.features {
  padding: 50px 0;
}

.features h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}

.features > p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.feature {
  text-align: center;
}

.feature-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.feature h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature p {
  font-size: 16px;
}
