.hero {
  text-align: center;
  padding: 50px 0;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-call,
.cta-create {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-call {
  background-color: #f0f0f0;
  color: #333;
}

.cta-create {
  background-color: #000;
  color: #fff;
}

.phone-mockup {
  margin-top: 50px;
  /* Add styles for phone mockup image */
}
